import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationAdaptive from "../../Navigation/NavigationAdaptive";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";
import { setData } from "../../Store/reducer";
import "../../Style/MainHeader.scss";
import TelegramIcon from "@mui/icons-material/Telegram";
import FlagIcon from "@mui/icons-material/Flag";
import PersonIcon from "@mui/icons-material/Person";
import { setDataNavBar } from "../../Store/reducerNavBar";
import SiteSettingsModal from "../../Modal/SiteSettingsModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Settings } from "../../images/settings.svg";

function Header({ isSmallScreen }) {
  const [cabinetOpen, setCabinetOpen] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const dispatch = useDispatch();
  const [siteSettings, setSiteSettings] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isClientCabinet, setIsClientCabinet] = useState(() => {
    const storedValue = localStorage.getItem("isClientCabinet");
    return storedValue ? JSON.parse(storedValue) : true;
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log(window.location.pathname);
  }, []);

  const handleModalOpen = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const openWalletPage = () => {
    navigate("wallet");
  };

  setTimeout(() => {
    setSiteSettings(JSON.parse(localStorage.getItem("site_settings")));
  }, 200);

  const handleMenuClick = () => {
    setIsDrawerOpen((prevIsOpen) => !prevIsOpen);
    dispatch(setDataNavBar(isDrawerOpen));
  };

  const handleOpenCabinet = () => {
    setIsRotated(!isRotated);
    setCabinetOpen(!cabinetOpen);
    dispatch(setData(cabinetOpen));
  };
  const handleOpenCabinetAdaptive = () => {
    setCabinetOpen(!cabinetOpen);
    dispatch(setData(cabinetOpen));
    const mainPage = document.getElementsByClassName("mainPageBody");
    const footerPage = document.getElementsByClassName("footerMainPageWrapper");
    const profileWrapper = document.getElementsByClassName("profileWrapper");
    const billsWrapper = document.getElementsByClassName("BillsWrapper");

    if (window.location.pathname === "/mainpage") {
      if (cabinetOpen) {
        mainPage[0].style.display = "none";
        footerPage[0].style.display = "none";
        document.body.style.overflow = "auto";
      } else {
        mainPage[0].style.display = "block";
        footerPage[0].style.display = "block";
        document.body.style.overflow = "auto";
      }
    } else if (
      window.location.pathname == "/mainpage/profile/profile" ||
      window.location.pathname == "/mainpage/profile/contacts" ||
      window.location.pathname == "/mainpage/profile/password" ||
      window.location.pathname == "/mainpage/profile/location" ||
      window.location.pathname == "/mainpage/profile/notification" ||
      window.location.pathname == "/mainpage/profile/verification" ||
      window.location.pathname == "/mainpage/profile/telegram"
    ) {
      if (cabinetOpen) {
        profileWrapper[0].style.display = "none";
        footerPage[0].style.display = "none";
        document.body.style.overflow = "auto";
      } else {
        profileWrapper[0].style.display = "block";
        footerPage[0].style.display = "block";
        document.body.style.overflow = "auto";
      }
    } else if (window.location.pathname === "/mainpage/bills") {
      if (cabinetOpen) {
        billsWrapper[0].style.display = "none";
        footerPage[0].style.display = "none";
        document.body.style.overflow = "auto";
      } else {
        billsWrapper[0].style.display = "block";
        footerPage[0].style.display = "block";
        document.body.style.overflow = "auto";
      }
    }
  };

  const style = {
    background: "white",
    display: "flex",
  };

  const iconStyle = {
    color: "black",
    transition: "transform 0.5s",
    transform: isRotated ? "rotate(0deg)" : "rotate(180deg)",
    cursor: "pointer",
  };

  return (
    <div className="landingHeaderWrapper" style={{ width: "100%" }}>
      <NavigationAdaptive
        isOpen={isDrawerOpen}
        isClose={isSmallScreen}
        onClose={handleMenuClick}
      />
      <div className="landingHeader">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={style}>
            <Toolbar
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              {isSmallScreen && (
                <IconButton
                  size="large"
                  edge="start"
                  color="black"
                  aria-label="menu"
                  sx={{ mr: 45 }}
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>
              )}
              {!isSmallScreen && (
                <div
                style={{
                  display:"flex",
                  justifyContent:"flex-end"
                }} 
                className="containerHeaderButton">
                  <button
                    style={{
                      borderRadius: "30px",
                      border: "none",
                    }}
                    className="yellowButton"
                    onClick={openWalletPage}
                  >
                    {t("buttonConnectWallet")}
                  </button>
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    className="buttonSettingsWrapper"
                    onClick={handleModalOpen}
                  >
                    <Settings />
                  </button>
                </div>
              )}
            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <SiteSettingsModal isOpen={isModalOpen} onClose={handleModalClose} />
    </div>
  );
}

export default Header;
