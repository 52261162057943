import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import SiteSettingsModal from "../Modal/SiteSettingsModal";
import { useTranslation } from "react-i18next";
import { ListItemIcon } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as Home } from "../images/Navigation/homeNav.svg";
import { ReactComponent as Cabinet } from "../images/Navigation/cabinetClient.svg";
import { ReactComponent as Profile } from "../images/Navigation/profileNav.svg";
import { ReactComponent as Exit } from "../images/Navigation/exitNav.svg";
import { ReactComponent as NavigationArrow } from "../images/Navigation/navigationArrow.svg";
import { ReactComponent as Structure } from "../images/Navigation/structure.svg";
import { ReactComponent as Handshake } from "../images/Navigation/handshake.svg";
import { ReactComponent as Events } from "../images/Navigation/events.svg";
import { ReactComponent as Transfer } from "../images/Navigation/transfer.svg";
import { ReactComponent as Verification } from "../images/Navigation/verification.svg";
import { ReactComponent as Agreement } from "../images/Navigation/Agreement.svg";
import { ReactComponent as Invoice } from "../images/Navigation/invoice.svg";
import { ReactComponent as Settings } from "../images/settings.svg";
import SearchIcon from "@mui/icons-material/Search";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

function NavigationAdaptive({ isOpen, onClose, isClose }) {
  const [activeItem, setActiveItem] = useState("Cabinet");
  const [isClientCabinet, setIsClientCabinet] = useState(() => {
    const storedValue = localStorage.getItem("isClientCabinet");
    return storedValue ? JSON.parse(storedValue) : true;
  });
  const [siteSettings, setSiteSettings] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [openStructure, setOpenStructure] = useState(false);
  const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);

  const handleToggleStructure = () => {
    setOpenStructure((prev) => !prev);
    setShowAdditionalButtons((prev) => !prev);
  };

  setTimeout(() => {
    setSiteSettings(JSON.parse(localStorage.getItem("site_settings")));
  }, 200);

  const handleModalOpen = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const navigate = useNavigate();

  const handleItemClick = (item) => {
    setActiveItem(item);

    const routes = {
      lorem: () => {
        navigate("/");
        onClose();
      },
      Cabinet: () => {
        navigate("/mainpage");
        onClose();
      },
      profile: () => {
        navigate("/mainpage/role");
        onClose();
      },
      searchusers: () => {
        navigate("/mainpage/searchusers");
        onClose();
      },
      partnership: () => {
        navigate("/mainpage/partnership");
        onClose();
      },
      events: () => {
        navigate("/mainpage/events");
        onClose();
      },
      transfers: () => {
        navigate("/mainpage/transfer");
        onClose();
      },
      verification: () => {
        navigate("/mainpage/verification");
        onClose();
      },
      agreement: () => {
        navigate("/mainpage/agreement");
        onClose();
      },
      invoice: () => {
        navigate("/mainpage/invoice");
        onClose();
      },
      CabinetPartnerNavStructureTable: () => {
        navigate("/mainpage/invite-structure");
        onClose();
      },
      CabinetPartnerNavStructureGraph: () => {
        navigate("/mainpage/graphical-structure");
        onClose();
      },
      wallet: () => {
        navigate("/mainpage/wallet");
        onClose();
      },
      exit: () => {
        navigate("/");
        onClose();
      },
    };

    const routeHandler = routes[item];
    if (routeHandler) {
      routeHandler();
    }
  };

  const handleCabinetChange = () => {
    setIsClientCabinet((prevIsClientCabinet) => !prevIsClientCabinet);
  };

  return (
    <>
      {isOpen && (
        <Drawer
          open={isOpen}
          sx={{
            //   width: drawerWidth,
            backgroundColor: "#1C2434",

            flexShrink: 0,
            "& .MuiDrawer-paper": {
              // width: drawerWidth,
              boxSizing: "border-box",
              position: "static",
              display: "flex",
              flexDirection: "column-reverse",
              backgroundColor: "#1C2434",
              justifyContent: "flex-end",
            },
          }}
          variant="temporary"
          anchor="left"
        >
          <List
            style={{
              width: "100%",
              backgroundColor: "#1C2434",
              color: "white",
            }}
          >
            <ListItemButton onClick={() => handleItemClick("lorem")}>
              <ListItemIcon>
                <Home
                  style={{ fill: "white", width: "20px", height: "20px" }}
                />
              </ListItemIcon>
              <ListItemText primary={t("lorem")} />
            </ListItemButton>
            <ListItemButton onClick={() => handleItemClick("Cabinet")}>
              <ListItemIcon>
                <Cabinet
                  style={{
                    width: "20px",
                    height: "20px",
                    stroke: activeItem === "cabinet" ? "red" : "black",
                    marginRight: "10px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("cabinetClient")} />
            </ListItemButton>
            <ListItemButton onClick={() => handleItemClick("profile")}>
              <ListItemIcon>
                <Profile
                  style={{
                    width: "25px",
                    height: "25px",
                    fill: "white",

                    marginRight: "10px",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("profile")} />
            </ListItemButton>
            <ListItemButton onClick={() => handleItemClick("searchusers")}>
              <ListItemIcon>
                <SearchIcon
                  style={{
                    width: "25px",
                    height: "25px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("bills")} />
            </ListItemButton>
            <ListItemButton
              style={{ display: "flex", color: "white" }}
              onClick={handleToggleStructure}
            >
              <ListItemIcon
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Structure
                  style={{
                    width: "25px",
                    height: "25px",
                    fill: "white",
                    marginRight: "25px",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("CabinetPartnerNavStructureMain")} />
              {openStructure ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            {openStructure && showAdditionalButtons && (
              <>
                <ListItemButton
                  style={{
                    width: "100%",
                    height: "45px",
                    fill: "white",
                    marginLeft: "45px",
                  }}
                  onClick={() =>
                    handleItemClick("CabinetPartnerNavStructureTable")
                  }
                >
                  <ListItemText
                    primary={t("CabinetPartnerNavStructureTable")}
                  />
                </ListItemButton>
                <ListItemButton
                  style={{
                    width: "100%",
                    height: "45px",
                    fill: "white",
                    marginLeft: "45px",
                  }}
                  onClick={() =>
                    handleItemClick("CabinetPartnerNavStructureGraph")
                  }
                >
                  <ListItemText
                    primary={t("CabinetPartnerNavStructureGraph")}
                  />
                </ListItemButton>
              </>
            )}
            <ListItemButton onClick={() => handleItemClick("partnership")}>
              <ListItemIcon>
                <Handshake
                  style={{
                    width: "30px",
                    height: "30px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("partners")} />
            </ListItemButton>
            <ListItemButton onClick={() => handleItemClick("events")}>
              <ListItemIcon>
                <Events
                  style={{
                    width: "30px",
                    height: "30px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("events")} />
            </ListItemButton>
            <ListItemButton onClick={() => handleItemClick("transfers")}>
              <ListItemIcon>
                <Transfer
                  style={{
                    width: "30px",
                    height: "30px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("transfers")} />
            </ListItemButton>
            <ListItemButton onClick={() => handleItemClick("verification")}>
              <ListItemIcon>
                <Verification
                  style={{
                    width: "30px",
                    height: "30px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("verification")} />
            </ListItemButton>
            <ListItemButton onClick={() => handleItemClick("agreement")}>
              <ListItemIcon>
                <Agreement
                  style={{
                    width: "30px",
                    height: "30px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("agreement")} />
            </ListItemButton>
            <ListItemButton onClick={() => handleItemClick("invoice")}>
              <ListItemIcon>
                <Invoice
                  style={{
                    width: "30px",
                    height: "30px",
                    fill: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("invoice")} />
            </ListItemButton>
            <ListItemButton onClick={() => handleItemClick("exit")}>
              <ListItemIcon>
                <Exit
                  style={{
                    width: "30px",
                    height: "30px",
                    stroke: "white",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t("exit")} />
            </ListItemButton>
            <ListItem
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <button
                className="yellowButton"
                style={{
                  width: "150px",
                  height: "34px",
                  borderRadius: "30px",
                  border: "none",
                  color: "white",
                  stroke: "white",
                }}
                onClick={() => handleItemClick("wallet")}
              >
                {t("buttonConnectWallet")}
              </button>
            </ListItem>
            <ListItem
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "35px",
              }}
            >
              <button
                onClick={handleModalOpen}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                }}
              >
                <Settings />
              </button>
            </ListItem>
          </List>
          <ListItem>
            <ClearIcon
              onClick={onClose}
              style={{
                color:"white"
              }}
            />
          </ListItem>
        </Drawer>
      )}
      <SiteSettingsModal isOpen={isModalOpen} onClose={handleModalClose} />
    </>
  );
}

export default NavigationAdaptive;
